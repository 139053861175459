import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Line, Group, Text } from "react-konva";

const getPointsForLineComponent = (pointIds, points) => {
  let arr = [];
  if (pointIds) {
    for (const id of pointIds) {
      const p = points.find((x) => x.id === id);
      if (p) {
        arr.push(p.x);
        arr.push(p.y);
      }
    }
  }

  return arr;
};

const getPointsForLineComponentInTemp = (points) => {
  let arr = [];
  for (const p of points) {
    arr.push(p.x);
    arr.push(p.y);
  }
  return arr;
};

export default function HoleComponent(props) {
  const [points, setPoints] = useState([]);

  useEffect(() => {
    if (props.data) {
      if (props.tempChimney === true) {
        setPoints(getPointsForLineComponentInTemp([...props.data.pointIds, props.currentPoint]));
      } else {
        setPoints(getPointsForLineComponent(props.data.pointIds, props.roofPoints));
      }
    }
  }, [props.data, props.currentPoint, props.roofPoints]);

  return (
    <Container>
      <Group>
        <Line points={points} fill={"#d4d4d4"} closed={true} stroke="#2e2e2e" strokeWidth={1} />
      </Group>
    </Container>
  );
}

const Container = styled(Group)``;
