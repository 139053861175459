import React, { FC, useLayoutEffect, useRef, useState } from "react";
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter";
import styled from "styled-components";
import { IReportReturnModel } from "../Model";

type Props = { report: IReportReturnModel };

const NotesPage: FC<Props> = ({ report }) => {
  return (
    <PageContainer>
      <PageWrapper>
        <PageHeader report={report} />

        <PageContent>
          <PageContentContent>
            <Section style={{ marginTop: "32px" }}>
              <TitleSection>Ogólne uwagi do kalkulacji</TitleSection>
              <ItemsContainer>
                {report.generalNotes.map((note, index) => {
                  if (note !== "") {
                    return <Item key={index}>{note}</Item>;
                  }
                })}
              </ItemsContainer>
            </Section>

            <Section style={{ marginTop: "32px" }}>
              <TitleSection>Inne uwagi do kalkulacji</TitleSection>
              <ItemsContainer>
                {report.additionalNotes.map((note, index) => {
                  if (note !== "") {
                    return <Item key={index}>{note}</Item>;
                  }
                })}
              </ItemsContainer>
            </Section>
          </PageContentContent>
        </PageContent>

        <PageFooter />
      </PageWrapper>
    </PageContainer>
  );
};
export default NotesPage;

const PageContainer = styled.div`
  display: table;
  margin: 0 auto;
  width: 794px !important;
  height: 1123px !important;
  background: #ffffff;
`;

const PageWrapper = styled.div`
  padding: 28px 36px 28px 36px;
  height: calc(1123px - 56px) !important;
`;

const PageContent = styled.div`
  height: 847px !important;
`;

const PageContentContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  font-size: 20px;
  font-weight: bold;

  color: #006bcc;
`;

const ItemsContainer = styled.ul`
  display: flex;
  flex-direction: column; /* Jeśli chcesz zachować układ pionowy */
  margin-top: 16px;
  padding-left: 20px;
`;

const Item = styled.li`
  position: relative;
  margin: 0 16px;
  padding: 4px 8px;
  font-size: 16px;
  color: #00539e;
`;
