import axios from "axios";

export const DOMA_API_URL = process.env.REACT_APP_DOMA_API_URL;

const client = axios.create({
  baseURL: DOMA_API_URL,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
  },
});

export default client;
