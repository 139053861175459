import React from "react";
import styled from "styled-components";

export default function Space() {
  return <Container></Container>;
}

const Container = styled.div`
  height: 30px;
  background: #dcdcdc;

  @media print {
    display: none !important;
  }
`;
