import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { Divider, Card } from "antd";
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter";
import { IReportReturnModel } from "../Model";

type Props = {
  report: IReportReturnModel;
  children?: ReactNode;
  topSpace: number;
};

const AutoPageTemplate: FC<Props> = ({ children, report, topSpace }) => {
  return (
    <PageWrapper>
      <PageHeader report={report} />

      <PageContent>
        <PageContentContent style={{ paddingTop: `${topSpace}px` }}>{children}</PageContentContent>
      </PageContent>

      <PageFooter />
    </PageWrapper>
  );
};
export default AutoPageTemplate;

const PageWrapper = styled.div`
  padding: 28px 36px 28px 36px;
  height: calc(1123px - 56px) !important;
`;

const PageContent = styled.div`
  height: 847px !important;
`;

const PageContentContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
