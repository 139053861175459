import React, { FC } from "react";
import styled from "styled-components";
import logoFooter from "../assets/images/logo-footer.png";

type Props = {};

const PageFooter: FC<Props> = () => {
  return (
    <Container>
      <PageFooterContent>
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <div style={{ fontSize: "14px" }}>Ofertę sporządzono przy pomocy</div>
        </div>

        <div style={{ display: "flex", alignItems: "flex-end", height: "100%" }}>
          <img src={logoFooter} style={{ width: "100px" }} />
        </div>
      </PageFooterContent>
    </Container>
  );
};
export default PageFooter;

const Container = styled.div`
  height: 40px !important;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const PageFooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 39px;
  width: 100%;
  border-top: 1px solid #1ab7dd;
  font-size: 18px;
  color: #7b695f;
`;
