import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import client from "./api/ApiClient";
import Report from "./sections/Report";
import { IInvestmentReturnModel } from "./Model";
import { ConfigProvider } from "antd";

function App() {
  const navigate = useNavigate();
  let { pathname } = useLocation();

  const [urlCode, setUrlCode] = useState<string>("");
  const [investment, setInvestment] = useState<IInvestmentReturnModel | null>(null);

  useEffect(() => {
    setUrlCode(getInvestmentCode());
  }, []);

  useEffect(() => {
    if (urlCode && urlCode != "") {
      client
        .get(`/Reports/${urlCode}`)
        .then((response) => {
          if (response.status == 200) {
            let data: IInvestmentReturnModel = response.data;
            // console.log("Dane inwestycji Json", data);
            setInvestment(data);
          }
        })
        .catch((error) => {});
    }
  }, [urlCode]);

  useEffect(() => {
    if (investment) {
      const investmentCode = getInvestmentCode();

      if (investment && investment.reports.length > 0) {
        const firstReport = investment.reports[0];
        const reportCode = getReportCode();

        if ((reportCode === undefined || reportCode === "") && firstReport) {
          navigate(`${investmentCode}/${firstReport.reportNumber}`);
        }
      }
    }
  }, [investment]);

  const getInvestmentCode = () => {
    return `${pathname.split("/")[1]}`;
  };

  const getReportCode = () => {
    const res = pathname.split("/")[2];
    return res ? res : undefined;
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          // fontFamily: "Fira Sans, sans-serif",
          fontFamily: "Roboto, sans-serif",
        },
      }}
    >
      <Container>
        {!investment && <Loading>Ładowanie kalkulacji...</Loading>}
        {investment && investment.reports.length > 0 && (
          <Report
            investment={investment}
            investmentCode={getInvestmentCode()}
            activeReportCode={getReportCode()}
          />
        )}
        {investment && investment.reports.length === 0 && (
          <NoActiveReport>
            <b style={{ fontSize: "24px" }}>OFERTA JEST JUŻ NIEAKTUALNA LUB NIE ISTNIEJE</b>
            <br />
            Skontaktuj się z przedstawicielem
          </NoActiveReport>
        )}
      </Container>
    </ConfigProvider>
  );
}

export default App;

const Container = styled.div`
  display: flex;
  background: #dcdcdc;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

const NoActiveReport = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  width: 100%;
  height: 100vh;
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  width: 100%;
  height: 100vh;
`;
