import React, { FC, useState } from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { IReportReturnModel } from "../Model";
import { getBasicUrlLogoImage, getBasicUrlSystemImage } from "../Helper";

export const getCategoryIdByCode = (codes: any, categories: any[]) => {
  const codesArray = Array.isArray(codes) ? codes : [codes];

  return codesArray.map((code) => {
    const category = categories.find((x) => x.code === code);
    return category ? category.id : null;
  });
};

export const getProductsByCategoryId = (categoryIds: any[], products: any[]) => {
  // Sprawdza, czy 'categoryIds' jest tablicą. Jeśli nie, zamienia ją na tablicę z jednym elementem.
  const idsArray = Array.isArray(categoryIds) ? categoryIds : [categoryIds];

  return products.filter((product: any) => idsArray.includes(product.product.productCategoryId));
};

export const getSumPrice = (products: any[]) => {
  let sum = 0;
  for (const p of products) {
    sum += p.sumPrice;
  }
  return sum;
};

type Props = {
  report: IReportReturnModel;
};

const Page2: FC<Props> = ({ report }) => {
  const CardComponent = ({ title, name, active, imageUrl, isInstallationCard }: any) => {
    let newName = active ? name : "Nie wybrano";

    if (isInstallationCard && active) {
      newName = (
        <div style={{ color: active && "#ffffff" }}>
          <div>Dach wraz z usługą montażu</div>
          <div style={{ fontSize: "8px" }}>
            Wycena usługi montażu dachu jest
            <br />
            przedmiotem odrębnej oferty
          </div>
        </div>
      );
    }
    return (
      <CardItem>
        <CardItemTitle span={10}>{title}</CardItemTitle>
        <CardItemContent span={14} style={{ background: active && "#66A6E0" }}>
          <CardItemContentRow>
            <CardItemContentCol span={14}>
              <CardItemContentColRow style={{ height: "20px", alignItems: "flex-start", fontSize: "16px" }}>
                {active ? (
                  <CheckCircleOutlined style={{ marginLeft: "8px", paddingTop: "8px", color: "#d2ff6a" }} />
                ) : (
                  <CloseCircleOutlined style={{ marginLeft: "8px", paddingTop: "8px", color: "#910000" }} />
                )}
              </CardItemContentColRow>
              <CardItemContentColRow
                style={{
                  height: "100%",
                  marginTop: "-20px",
                  padding: "0px 16px",
                  textAlign: "center",
                  color: active ? "#FFFFFF" : "#848080",
                  fontWeight: "500",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  {newName}
                </div>
              </CardItemContentColRow>
            </CardItemContentCol>
            <CardItemContentCol
              span={10}
              style={{
                display: "flex",
                padding: "8px 8px 8px 0px",
              }}
            >
              <ImageContainer>
                {imageUrl && imageUrl !== "" ? (
                  <img style={{ maxHeight: "101px", objectFit: "contain" }} src={imageUrl} />
                ) : (
                  <img
                    style={{ maxWidth: "120px", maxHeight: "92px", objectFit: "contain" }}
                    src={`${getBasicUrlLogoImage()}${report.logoUrl}`}
                  />
                )}
              </ImageContainer>
            </CardItemContentCol>
          </CardItemContentRow>
        </CardItemContent>
      </CardItem>
    );
  };

  return (
    <PageContainer>
      <PageWrapper>
        <PageHeader report={report} />

        <PageContent>
          <PageContentContent>
            <CardContainer>
              <CardComponent
                title="System pokrycia"
                name={report.roofSystemName}
                active={report.roofSystemName.length > 0}
                imageUrl={
                  report.roofSystemImageUrl.length > 0
                    ? `${getBasicUrlSystemImage()}${report.roofSystemImageUrl}`
                    : ""
                }
                isInstallationCard={false}
              />
              <CardComponent
                title="Krycie wstępne"
                name={report.preCoveringSystemName}
                active={report.preCoveringSystemName.length > 0}
                imageUrl={"https://s3.doma.pl/tymczasowe/membrana.png"}
                isInstallationCard={false}
              />
              <CardComponent
                title="Okna dachowe, wyłazy"
                name={report.roofWindowsName}
                active={report.roofWindowsName.length > 0}
                imageUrl={"https://s3.doma.pl/tymczasowe/okna.png"}
                isInstallationCard={false}
              />

              <CardComponent
                title="System rynnowy"
                name={report.gutterSystemName}
                active={report.gutterSystemName.length > 0}
                imageUrl={
                  report.gutterSystemImageUrl.length > 0
                    ? `${getBasicUrlSystemImage()}${report.gutterSystemImageUrl}`
                    : ""
                }
                isInstallationCard={false}
              />
              <CardComponent
                title="Technika zamocowań"
                name={report.fasteningSystemName}
                active={report.fasteningSystemName.length > 0}
                imageUrl={"https://s3.doma.pl/tymczasowe/zamocowania.png"}
                isInstallationCard={false}
              />
              <CardComponent
                title="Montaż"
                name={report.labourName}
                active={report.labourName.length > 0}
                imageUrl={"https://s3.doma.pl/tymczasowe/montaz.png"}
                isInstallationCard={true}
              />
            </CardContainer>
          </PageContentContent>
        </PageContent>

        <PageFooter />
      </PageWrapper>
    </PageContainer>
  );
};
export default Page2;

const PageContainer = styled.div`
  display: table;
  margin: 0 auto;
  width: 794px !important;
  height: 1123px !important;
  background: #ffffff;
`;

const PageWrapper = styled.div`
  padding: 28px 36px 28px 36px;
  height: calc(1123px - 56px) !important;
`;

const PageContent = styled.div`
  height: 847px !important;
`;

const PageContentContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const CardItem = styled(Row)`
  display: flex;
  width: 100%;
  height: 117px;
`;

const CardItemTitle = styled(Col)`
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: 100%;
  font-size: 22px;
  font-weight: 500;
  color: #848080;
`;

const CardItemContent = styled(Col)`
  width: 100%;
  background-color: #ececec;
  border-radius: 8px;
`;

const CardItemContentRow = styled(Row)`
  width: 100%;
  height: 100%;
`;

const CardItemContentCol = styled(Col)`
  width: 100%;
  height: 100%;
`;

const CardItemContentColRow = styled.div`
  display: flex;
  font-size: 12px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  overflow: hidden;
`;
