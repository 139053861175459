import { Col, Row, Tag, Tooltip } from "antd";
import React, { FC } from "react";
import styled from "styled-components";
import { formatNumber, showMoney } from "../Helper";
import { ICategoryProduct } from "../Model";

// Dane całej tabeli z produktami
export interface ICategoryTable {
  title: string;
  totalNett: number;
  totalGross: number;
  isFull: boolean; // Czy pełna tabela danych
  showCount: boolean; // Czy pokazywać ilość - dla niepełnej tabeli
  items: ICategoryTableItem[];
}

export interface ICategoryTableItem {
  name: string;
  count: number;
  unit: string;
  pricePerUnit: number;
  nett: number;
  gross: number;
}

type Props = {
  position: ICategoryProduct;
  marginTop: number;
  isFull: boolean;
  showCount: boolean;
  short?: boolean;
};

const DomaTableComponent: FC<Props> = ({ position, marginTop, isFull, showCount, short }) => {
  const getProducts = (position: ICategoryProduct) => {
    if (short) {
      return position.products;
    } else {
      return position.products.sort((a, b) => b.amount - a.amount);
    }
  };

  return (
    <Container data-role="doma-table" style={{ marginTop: `${marginTop}px` }}>
      <DomaTableTitle data-role="doma-table-title">
        <div style={{ fontSize: "18px", color: "#006BCC" }}>{position.name}</div>
        <div style={{ fontSize: "18px", color: "#006BCC" }}>
          <Tag style={{ fontSize: "18px", color: "#006BCC", padding: "4px 8px" }}>
            <span style={{ fontSize: "10px", color: "#272727" }}>NETTO</span> {showMoney(position.nettPrice)} zł
          </Tag>

          <Tag style={{ fontSize: "18px", color: "#006BCC", padding: "4px 8px" }}>
            <span style={{ fontSize: "10px", color: "#272727" }}>BRUTTO</span> {showMoney(position.price)} zł
          </Tag>
        </div>
      </DomaTableTitle>
      <DomaTableHeader data-role="doma-table-header">
        <Row style={{ paddingLeft: "8px" }}>
          {isFull ? (
            <>
              <Col span={12} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                Nazwa produktu
              </Col>
              <Col span={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                Ilość
              </Col>
              <Col span={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                Jdn.
              </Col>
              <Col span={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                Cena/jdn.
              </Col>
              <Col span={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                Netto
              </Col>
              <Col span={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                Brutto
              </Col>
            </>
          ) : showCount ? (
            <>
              <Col span={18} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                Nazwa produktu
              </Col>
              <Col span={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                Ilość
              </Col>
              <Col span={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                Jdn.
              </Col>
            </>
          ) : (
            <>
              <Col span={24} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                Nazwa produktu
              </Col>
            </>
          )}
        </Row>
      </DomaTableHeader>
      <DomaTableContent data-role="doma-table-content">
        {position.products.length === 0 ? (
          <DomaTableItem
            data-role="doma-table-item"
            style={{
              backgroundColor: "#f0f0f0",
            }}
          >
            <Row style={{ height: "22px" }}>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "500",
                  fontSize: "10px",
                }}
              >
                Brak produktów
              </Col>
            </Row>
          </DomaTableItem>
        ) : (
          getProducts(position).map((item, i) => {
            return (
              <DomaTableItem
                key={i}
                data-role="doma-table-item"
                style={{
                  backgroundColor: i % 2 === 0 ? "#f0f0f0" : "#ffffff",
                }}
              >
                <Row style={{ display: "flex", alignItems: "center", height: "22px" }}>
                  {isFull ? (
                    <>
                      <Col
                        span={12}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          fontWeight: "500",
                          fontSize: "10px",
                          lineHeight: "12px",
                          cursor: "pointer",
                        }}
                        title={item.name}
                      >
                        <ClampText>{item.name}</ClampText>
                      </Col>
                      <Col
                        span={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                        }}
                      >
                        {formatNumber(item.amount)}
                      </Col>
                      <Col
                        span={3}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                        }}
                      >
                        {item.unit}
                      </Col>
                      <Col
                        span={3}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                        }}
                      >
                        {showMoney(item.nettUnitPrice)}
                      </Col>
                      <Col
                        span={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                        }}
                      >
                        {showMoney(item.nettPrice)}
                      </Col>
                      <Col
                        span={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                        }}
                      >
                        {showMoney(item.price)}
                      </Col>
                    </>
                  ) : showCount ? (
                    <>
                      <Col
                        span={18}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          fontWeight: "500",
                          fontSize: "10px",
                          cursor: "pointer",
                        }}
                        title={item.name}
                      >
                        <ClampText>{item.name}</ClampText>
                      </Col>
                      <Col
                        span={3}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                        }}
                      >
                        {formatNumber(item.amount)}
                      </Col>
                      <Col
                        span={3}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                        }}
                      >
                        {item.unit}
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          fontWeight: "500",
                          fontSize: "10px",
                          cursor: "pointer",
                        }}
                        title={item.name}
                      >
                        <ClampText>{item.name}</ClampText>
                      </Col>
                    </>
                  )}
                </Row>
              </DomaTableItem>
            );
          })
        )}
      </DomaTableContent>
    </Container>
  );
};
export default DomaTableComponent;

const Container = styled.div``;

const DomaTableTitle = styled.div`
  display: flex;
  justify-content: space-between;
  display: flex;
  padding: 12px 0px 12px 0px;
  font-weight: bold;
`;
const DomaTableHeader = styled.div`
  padding: 16px;
  font-weight: bold;
  color: #0555a0;
`;
const DomaTableContent = styled.div`
  border: 1px solid #00539e;
`;

const DomaTableItem = styled.div`
  padding: 6px 8px 6px 16px;
  color: #615e5e;
`;

const ClampText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Ilość linii, na które chcesz ograniczyć tekst */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
