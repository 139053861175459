import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Line, Group, Text } from "react-konva";
import { Html } from "react-konva-utils";
import { getColorByEdgeType } from "../Helper";
import { Tag } from "antd";

const getPointsForLineComponent = (pointIds, points) => {
  let arr = [];
  for (const id of pointIds) {
    const p = points.find((x) => x.id === id);

    if (p) {
      arr.push(p.x);
      arr.push(p.y);
    }
  }
  return arr;
};

const midpoint = (point1, point2) => {
  const x1 = point1.x;
  const y1 = point1.y;
  const x2 = point2.x;
  const y2 = point2.y;

  const midX = (x1 + x2) / 2;
  const midY = (y1 + y2) / 2;

  return { x: midX, y: midY };
};

const getMiddlePoint = (p1Id, p2Id, roofPoints) => {
  const p1 = roofPoints.find((x) => x.id === p1Id);
  const p2 = roofPoints.find((x) => x.id === p2Id);
  return midpoint(p1, p2);
};

export default function Edge(props) {
  const [roofEdge, setRoofEdge] = useState(props.roofEdge);
  useEffect(() => {
    setRoofEdge(props.roofEdge);
  }, [props.roofEdge]);

  return (
    <Container>
      <Line
        points={getPointsForLineComponent([roofEdge.startPointId, roofEdge.endPointId], props.roofPoints)}
        closed={false}
        stroke={getColorByEdgeType(roofEdge.type)}
        strokeWidth={3}
      />

      <Group
        x={getMiddlePoint(roofEdge.startPointId, roofEdge.endPointId, props.roofPoints).x}
        y={getMiddlePoint(roofEdge.startPointId, roofEdge.endPointId, props.roofPoints).y}
      >
        <Html>
          <Box color={getColorByEdgeType(roofEdge.type)} style={{ color: "#ffffff" }}>
            {roofEdge.type}
          </Box>
        </Html>
      </Group>
    </Container>
  );
}

const Container = styled(Group)``;

const Box = styled(Tag)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  padding: 2px 0px;
  font-size: 12px;
  line-height: 12px;
`;
