import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter";
import styled from "styled-components";
import Konva from "konva";
import { Layer, Stage } from "react-konva";
import RoofPart from "../components/RoofPart";
import { getCorrectedPoints } from "./Page1";
import HoleComponent from "../components/HoleComponent";
import Edge from "../components/Edge";
import DimLine from "../components/DimLine";
import { Col, Row, Tag } from "antd";
import { IReportReturnModel } from "../Model";
import { getColorByEdgeType } from "../Helper";

const MARGIN = 0;
const HEIGHT_DRAW_AREA = 530;
const WIDTH_DRAW_AREA = 690;

type Props = { report: IReportReturnModel };

const GeometricPage: FC<Props> = ({ report }) => {
  const stage = useRef<Konva.Stage>(null); // Referencja do obszaru
  const stageParent = useRef<HTMLDivElement>(null); // Referencja do rodzica stage
  const [dimStageParent, setDimStageParent] = useState({ width: 0, height: 0 }); // wymiary parent stage

  const [correctedRoofPoints, setCorrectedRoofPoints] = useState<any[]>([]);

  useEffect(() => {
    if (report) {
      const roofData = JSON.parse(report.roofData);

      if (roofData && roofData.roofPoints) {
        setCorrectedRoofPoints(getCorrectedPoints(roofData.roofPoints, WIDTH_DRAW_AREA, HEIGHT_DRAW_AREA, 5));
      }
    }
  }, [report, dimStageParent]);

  // Przypisanie wymiarów parent stage
  useLayoutEffect(() => {
    if (stageParent.current) {
      setDimStageParent({
        width: stageParent.current.offsetWidth,
        height: stageParent.current.offsetHeight,
      });
    }
  }, []);

  const getMinX = (points: any[]) => {
    return Math.min(...points.map((item) => item.x));
  };

  const getMaxX = (points: any[]) => {
    return Math.max(...points.map((item) => item.x));
  };

  const getMinY = (points: any[]) => {
    return Math.min(...points.map((item) => item.y));
  };

  const getMaxY = (points: any[]) => {
    return Math.max(...points.map((item) => item.y));
  };

  const getDrawWidth = () => {
    const maxXPoints = Math.max(...correctedRoofPoints.map((o) => o.x));
    return maxXPoints;
  };

  const getXMargin = () => {
    const drawWidth = getDrawWidth();
    if (drawWidth > 0) {
      return (WIDTH_DRAW_AREA - drawWidth) / 2;
    }
    return 0;
  };

  const getYMargin = () => {
    const drawHeight = Math.max(...correctedRoofPoints.map((o) => o.y));
    if (drawHeight > 0) {
      return (HEIGHT_DRAW_AREA - drawHeight) / 2;
    }
    return 0;
  };

  const getDistanceTwoPoints = (x1: number, x2: number, scale: number) => {
    const distance = Math.abs(x2 - x1);
    return distance / scale;
  };

  const getRoofDataObject = () => {
    if (report) {
      return JSON.parse(report.roofData);
    }
    return null;
  };

  return (
    <PageContainer>
      <PageWrapper>
        <PageHeader report={report} />

        <PageContent>
          <PageContentContent>
            <DrawContainer ref={stageParent}>
              <Stage ref={stage} width={WIDTH_DRAW_AREA} height={HEIGHT_DRAW_AREA}>
                <Layer offsetX={-1 * getXMargin()} offsetY={-1 * getYMargin()}>
                  {/* Połacie */}
                  {getRoofDataObject()?.roofPlanes?.map((o: any) => {
                    return (
                      <RoofPart
                        key={o.id}
                        roofPart={o}
                        roofPoints={getCorrectedPoints(
                          getRoofDataObject().roofPoints,
                          WIDTH_DRAW_AREA,
                          HEIGHT_DRAW_AREA,
                          MARGIN
                        )}
                        roofEdges={getRoofDataObject().roofEdges}
                        basic={false}
                      />
                    );
                  })}

                  {/* Okna */}
                  {getRoofDataObject()?.roofHoles?.map((o: any, i: any) => {
                    {
                      return (
                        <HoleComponent
                          key={i}
                          data={o}
                          roofPoints={getCorrectedPoints(
                            getRoofDataObject().roofPoints,
                            WIDTH_DRAW_AREA,
                            HEIGHT_DRAW_AREA,
                            MARGIN
                          )}
                        />
                      );
                    }
                  })}

                  {/* Krawędzie */}
                  {getRoofDataObject()?.roofEdges?.map((o: any, i: any) => {
                    return (
                      <Edge
                        key={i}
                        roofEdge={o}
                        roofPoints={getCorrectedPoints(
                          getRoofDataObject().roofPoints,
                          WIDTH_DRAW_AREA,
                          HEIGHT_DRAW_AREA,
                          MARGIN
                        )}
                      />
                    );
                  })}

                  {/* Kominy */}
                  {getRoofDataObject()?.roofChimneys?.map((o: any, i: any) => {
                    return (
                      <HoleComponent
                        key={i}
                        data={o}
                        roofPoints={getCorrectedPoints(
                          getRoofDataObject().roofPoints,
                          WIDTH_DRAW_AREA,
                          HEIGHT_DRAW_AREA,
                          MARGIN
                        )}
                        chimney={true}
                      />
                    );
                  })}

                  {/* Wymiarowanie */}
                  <DimLine
                    distance={getDistanceTwoPoints(
                      getMinX(getRoofDataObject().roofPoints),
                      getMaxX(getRoofDataObject().roofPoints),
                      getRoofDataObject().scale
                    )}
                    points={[
                      getMinX(
                        getCorrectedPoints(
                          getRoofDataObject().roofPoints,
                          WIDTH_DRAW_AREA,
                          HEIGHT_DRAW_AREA,
                          MARGIN
                        )
                      ),
                      getMaxY(
                        getCorrectedPoints(
                          getRoofDataObject().roofPoints,
                          WIDTH_DRAW_AREA,
                          HEIGHT_DRAW_AREA,
                          MARGIN
                        )
                      ) + 50,
                      getMaxX(
                        getCorrectedPoints(
                          getRoofDataObject().roofPoints,
                          WIDTH_DRAW_AREA,
                          HEIGHT_DRAW_AREA,
                          MARGIN
                        )
                      ),
                      getMaxY(
                        getCorrectedPoints(
                          getRoofDataObject().roofPoints,
                          WIDTH_DRAW_AREA,
                          HEIGHT_DRAW_AREA,
                          MARGIN
                        )
                      ) + 50,
                    ]}
                    rotated={false}
                  />

                  <DimLine
                    distance={getDistanceTwoPoints(
                      getMinY(getRoofDataObject().roofPoints),
                      getMaxY(getRoofDataObject().roofPoints),
                      getRoofDataObject().scale
                    )}
                    points={[
                      getMaxX(
                        getCorrectedPoints(
                          getRoofDataObject().roofPoints,
                          WIDTH_DRAW_AREA,
                          HEIGHT_DRAW_AREA,
                          MARGIN
                        )
                      ) + 50,
                      getMaxY(
                        getCorrectedPoints(
                          getRoofDataObject().roofPoints,
                          WIDTH_DRAW_AREA,
                          HEIGHT_DRAW_AREA,
                          MARGIN
                        )
                      ),
                      getMaxX(
                        getCorrectedPoints(
                          getRoofDataObject().roofPoints,
                          WIDTH_DRAW_AREA,
                          HEIGHT_DRAW_AREA,
                          MARGIN
                        )
                      ) + 50,
                      getMinY(
                        getCorrectedPoints(
                          getRoofDataObject().roofPoints,
                          WIDTH_DRAW_AREA,
                          HEIGHT_DRAW_AREA,
                          MARGIN
                        )
                      ),
                    ]}
                    rotated={true}
                  />
                </Layer>
              </Stage>
            </DrawContainer>

            <TitleSection>Podsumowanie geometryczne dachu</TitleSection>

            <EdgesContainer>
              <EdgesContainerColumn>
                <EdgeItem style={{ marginTop: "8px" }}>
                  <EdgeItemCol span={16} style={{ justifyContent: "flex-start", fontWeight: "bold" }}>
                    Suma powierzchni połaci
                  </EdgeItemCol>
                  <EdgeItemCol span={5}>{report.roofArea.toFixed(2)}</EdgeItemCol>
                  <EdgeItemCol span={3} style={{ justifyContent: "flex-end" }}>
                    m2
                  </EdgeItemCol>
                </EdgeItem>
                <EdgeItem>
                  <EdgeItemCol
                    span={3}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <EdgeTag color={getColorByEdgeType(1)}>1</EdgeTag>
                  </EdgeItemCol>
                  <EdgeItemCol
                    span={13}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    Okap
                  </EdgeItemCol>
                  <EdgeItemCol span={5}>{report.eavesLength.toFixed(2)}</EdgeItemCol>
                  <EdgeItemCol span={3} style={{ justifyContent: "flex-end" }}>
                    mb
                  </EdgeItemCol>
                </EdgeItem>
                <EdgeItem>
                  <EdgeItemCol
                    span={3}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <EdgeTag color={getColorByEdgeType(5)}>5</EdgeTag>
                  </EdgeItemCol>
                  <EdgeItemCol span={13} style={{ justifyContent: "flex-start" }}>
                    Kalenica pozioma
                  </EdgeItemCol>
                  <EdgeItemCol span={5}>{report.ridgesLength.toFixed(2)}</EdgeItemCol>
                  <EdgeItemCol span={3} style={{ justifyContent: "flex-end" }}>
                    mb
                  </EdgeItemCol>
                </EdgeItem>
                <EdgeItem>
                  <EdgeItemCol
                    span={3}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <EdgeTag color={getColorByEdgeType(3)}>3</EdgeTag>
                  </EdgeItemCol>
                  <EdgeItemCol span={13} style={{ justifyContent: "flex-start" }}>
                    Kalenica narożna
                  </EdgeItemCol>
                  <EdgeItemCol span={5}>{report.edgeRidgesLength.toFixed(2)}</EdgeItemCol>
                  <EdgeItemCol span={3} style={{ justifyContent: "flex-end" }}>
                    mb
                  </EdgeItemCol>
                </EdgeItem>
                <EdgeItem>
                  <EdgeItemCol
                    span={3}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <EdgeTag color={getColorByEdgeType(4)}>4</EdgeTag>
                  </EdgeItemCol>
                  <EdgeItemCol span={13} style={{ justifyContent: "flex-start" }}>
                    Koszowa
                  </EdgeItemCol>
                  <EdgeItemCol span={5}>{report.valleyLength.toFixed(2)}</EdgeItemCol>
                  <EdgeItemCol span={3} style={{ justifyContent: "flex-end" }}>
                    mb
                  </EdgeItemCol>
                </EdgeItem>
                <EdgeItem>
                  <EdgeItemCol
                    span={3}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <EdgeTag color={getColorByEdgeType(10)}>10</EdgeTag>
                  </EdgeItemCol>
                  <EdgeItemCol span={13} style={{ justifyContent: "flex-start" }}>
                    Dach-ściana
                  </EdgeItemCol>
                  <EdgeItemCol span={5}>{report.roofWallLength.toFixed(2)}</EdgeItemCol>
                  <EdgeItemCol span={3} style={{ justifyContent: "flex-end" }}>
                    mb
                  </EdgeItemCol>
                </EdgeItem>
              </EdgesContainerColumn>
              <EdgesContainerColumn>
                <EdgeItem style={{ marginTop: "8px" }}>
                  <EdgeItemCol
                    span={3}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <EdgeTag color={getColorByEdgeType(7)}>7</EdgeTag>
                  </EdgeItemCol>
                  <EdgeItemCol span={13} style={{ justifyContent: "flex-start" }}>
                    Szczytowa lewa
                  </EdgeItemCol>
                  <EdgeItemCol span={5}>{report.leftRakeLength.toFixed(2)}</EdgeItemCol>
                  <EdgeItemCol span={3} style={{ justifyContent: "flex-end" }}>
                    mb
                  </EdgeItemCol>
                </EdgeItem>
                <EdgeItem>
                  <EdgeItemCol
                    span={3}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <EdgeTag color={getColorByEdgeType(6)}>6</EdgeTag>
                  </EdgeItemCol>
                  <EdgeItemCol span={13} style={{ justifyContent: "flex-start" }}>
                    Szczytowa prawa
                  </EdgeItemCol>
                  <EdgeItemCol span={5}>{report.rightRakeLength.toFixed(2)}</EdgeItemCol>
                  <EdgeItemCol span={3} style={{ justifyContent: "flex-end" }}>
                    mb
                  </EdgeItemCol>
                </EdgeItem>
                <EdgeItem>
                  <EdgeItemCol
                    span={3}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <EdgeTag color={getColorByEdgeType(9)}>9</EdgeTag>
                  </EdgeItemCol>
                  <EdgeItemCol span={13} style={{ justifyContent: "flex-start" }}>
                    Otwór
                  </EdgeItemCol>
                  <EdgeItemCol span={5}>{report.holeLength.toFixed(2)}</EdgeItemCol>
                  <EdgeItemCol span={3} style={{ justifyContent: "flex-end" }}>
                    mb
                  </EdgeItemCol>
                </EdgeItem>
                <EdgeItem>
                  <EdgeItemCol
                    span={3}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <EdgeTag color={getColorByEdgeType(11)}>11</EdgeTag>
                  </EdgeItemCol>
                  <EdgeItemCol span={13} style={{ justifyContent: "flex-start" }}>
                    Okno
                  </EdgeItemCol>
                  <EdgeItemCol span={5}>{report.windowLength.toFixed(2)}</EdgeItemCol>
                  <EdgeItemCol span={3} style={{ justifyContent: "flex-end" }}>
                    mb
                  </EdgeItemCol>
                </EdgeItem>
                <EdgeItem>
                  <EdgeItemCol
                    span={3}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  >
                    <EdgeTag color={getColorByEdgeType(12)}>12</EdgeTag>
                  </EdgeItemCol>
                  <EdgeItemCol span={13} style={{ justifyContent: "flex-start" }}>
                    Komin
                  </EdgeItemCol>
                  <EdgeItemCol span={5}>{report.chimneyLength.toFixed(2)}</EdgeItemCol>
                  <EdgeItemCol span={3} style={{ justifyContent: "flex-end" }}>
                    mb
                  </EdgeItemCol>
                </EdgeItem>
                <EdgeItem>
                  <EdgeItemCol
                    span={3}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  ></EdgeItemCol>
                  <EdgeItemCol span={13} style={{ justifyContent: "flex-start" }}>
                    Inne
                  </EdgeItemCol>
                  <EdgeItemCol span={5}>{report.othersLength.toFixed(2)}</EdgeItemCol>
                  <EdgeItemCol span={3} style={{ justifyContent: "flex-end" }}>
                    mb
                  </EdgeItemCol>
                </EdgeItem>
              </EdgesContainerColumn>
            </EdgesContainer>
          </PageContentContent>
        </PageContent>

        <PageFooter />
      </PageWrapper>
    </PageContainer>
  );
};
export default GeometricPage;

const PageContainer = styled.div`
  display: table;
  margin: 0 auto;
  width: 794px !important;
  height: 1123px !important;
  background: #ffffff;
`;

const PageWrapper = styled.div`
  padding: 28px 36px 28px 36px;
  height: calc(1123px - 56px) !important;
`;

const PageContent = styled.div`
  height: 847px !important;
`;

const PageContentContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DrawContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 590px;
  /* background: #f5f5f5; */
`;

const EdgesContainer = styled.div`
  display: flex;
  justify-content: space-between;

  gap: 16px;
  height: 170px;
  /* margin-bottom: 32px; */
`;

const EdgesContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;

  background: #f5f5f5;
`;

const EdgeItem = styled(Row)`
  display: flex;
  align-items: center;
  width: 100%;
  color: #0555a0;
  padding: 4px 16px 0px 16px;
`;

const EdgeItemCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 18px;
  font-weight: bold;

  color: #00539e;
`;

const EdgeTag = styled(Tag)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  padding: 2px 0px;
  font-size: 12px;
  line-height: 12px;
`;
