import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Line, Group, Text } from "react-konva";

// Przełożenie punktów na kolekcję dla komponentu Line
const getPointsForLineComponent = (pointIds, roofPoints) => {
  let arr = [];
  for (const id of pointIds) {
    const p = roofPoints.find((x) => x.id === id);
    if (p) {
      arr.push(p.x);
      arr.push(p.y);
    }
  }
  return arr;
};

// Lista punktów na podstawie ich ids
const getFullPoints = (pointIds, points) => {
  let arr = [];
  for (const id of pointIds) {
    const p = points.find((x) => x.id === id);
    arr.push(p);
  }
  return arr;
};

// Współrzędne środka połaci
const getPointsForCenetrRoofPart = (pts) => {
  var first = pts[0],
    last = pts[pts.length - 1];
  if (first.x != last.x || first.y != last.y) pts.push(first);
  var twicearea = 0,
    x = 0,
    y = 0,
    nPts = pts.length,
    p1,
    p2,
    f;
  for (var i = 0, j = nPts - 1; i < nPts; j = i++) {
    p1 = pts[i];
    p2 = pts[j];
    f = p1.x * p2.y - p2.x * p1.y;
    twicearea += f;
    x += (p1.x + p2.x) * f;
    y += (p1.y + p2.y) * f;
  }
  f = twicearea * 3;
  return { x: x / f, y: y / f };
};

export default function RoofPart(props) {
  const [roofPart, setRoofPart] = useState(null);

  useEffect(() => {
    setRoofPart(props.roofPart);
  }, [props.roofPart]);

  return (
    <Container>
      {roofPart && (
        <Group>
          <Line
            points={getPointsForLineComponent(roofPart.pointIds, props.roofPoints)}
            fill={props.basic === false ? "#f5f5f5cc" : "#0000001A"}
            closed={true}
            stroke="#4e4e4e"
            strokeWidth={2}
          />

          {props.basic === false && getFullPoints(roofPart?.pointIds, props?.roofPoints).length > 0 && (
            <Text
              fontStyle="bold"
              fill="#4d4d4d"
              fontSize={14}
              x={getPointsForCenetrRoofPart(getFullPoints(roofPart?.pointIds, props?.roofPoints)).x}
              y={getPointsForCenetrRoofPart(getFullPoints(roofPart?.pointIds, props?.roofPoints)).y}
              text={`∢${roofPart.angle.toFixed(0)}°`}
            />
          )}
        </Group>
      )}
    </Container>
  );
}

const Container = styled(Group)``;
