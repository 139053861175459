import React, { FC, useState } from "react";
import styled from "styled-components";
import { useEffect } from "react";
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter";
import { Col, Divider, List, Row, Table, Typography } from "antd";
import { IReportReturnModel } from "../Model";
import { showMoney } from "../Helper";

const tableColumns = [
  {
    title: "Nazwa",
    dataIndex: "productName",
  },
  {
    title: "Cena pozycji [zł]",
    dataIndex: "priceSum",
    width: "200px",
  },
];

export const getCategoryIdByCode = (codes: any, categories: any[]) => {
  // Sprawdza, czy 'codes' jest tablicą. Jeśli nie, zamienia ją na tablicę z jednym elementem.
  const codesArray = Array.isArray(codes) ? codes : [codes];

  return codesArray.map((code) => {
    const category = categories.find((x) => x.code === code);
    return category ? category.id : null;
  });
};

// export const getProductsByCategoryId = (categoryId, products) => {
//   return products.filter((x) => x.product.productCategoryId === categoryId);
// };

export const getProductsByCategoryId = (categoryIds: any[], products: any[]) => {
  // Sprawdza, czy 'categoryIds' jest tablicą. Jeśli nie, zamienia ją na tablicę z jednym elementem.
  const idsArray = Array.isArray(categoryIds) ? categoryIds : [categoryIds];

  return products.filter((product: any) => idsArray.includes(product.product.productCategoryId));
};

export const getSumPrice = (products: any[]) => {
  let sum = 0;
  for (const p of products) {
    sum += p.sumPrice;
  }
  return sum;
};

type Props = {
  report: IReportReturnModel;
};

const Page3: FC<Props> = ({ report }) => {
  return (
    <PageContainer>
      <PageWrapper>
        <PageHeader report={report} />

        <PageContent>
          <PageContentContent>
            <MainTableContainer>
              <List
                dataSource={report.categoryPrices}
                header={
                  <MainTableItemContainer>
                    <MainTableItem
                      span={14}
                      style={{ fontWeight: "normal", fontSize: "12px", padding: "0px" }}
                    ></MainTableItem>
                    <MainTableItem
                      span={5}
                      style={{
                        fontWeight: "normal",
                        fontSize: "12px",
                        padding: "0px",
                        textAlign: "right",
                        paddingRight: "32px",
                      }}
                    >
                      Netto
                    </MainTableItem>
                    <MainTableItem
                      span={5}
                      style={{
                        fontWeight: "normal",
                        fontSize: "12px",
                        padding: "0px",
                        textAlign: "right",
                        paddingRight: "32px",
                      }}
                    >
                      Brutto
                    </MainTableItem>
                  </MainTableItemContainer>
                }
                split={false}
                renderItem={(item, i) => {
                  return (
                    <List.Item
                      style={{
                        background: i % 2 === 0 ? "#FFFFFF" : "transparent",
                        width: "100%",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      <MainTableItemContainer>
                        <MainTableItem span={14} style={{ fontWeight: "bold", textAlign: "left" }}>
                          {item.name}
                        </MainTableItem>
                        <MainTableItem span={5} style={{ fontWeight: "bold", textAlign: "right" }}>
                          {showMoney(item.nettPrice)} zł
                        </MainTableItem>
                        <MainTableItem
                          span={5}
                          style={{ fontWeight: "bold", textAlign: "right", paddingRight: "16px" }}
                        >
                          {showMoney(item.price)} zł
                        </MainTableItem>
                      </MainTableItemContainer>
                    </List.Item>
                  );
                }}
              />

              <Divider style={{ margin: "16px 0px 16px 0px", background: "#FBBA07" }} />

              <MainTableItemContainer>
                <MainTableItem span={19} style={{ textAlign: "left", color: "#7B695F" }}>
                  SUMA netto
                </MainTableItem>
                <MainTableItem
                  span={5}
                  style={{
                    fontWeight: "bold",
                    color: "#006BCC",
                    fontSize: "18px",
                    textAlign: "right",
                    paddingRight: "16px",
                  }}
                >
                  {showMoney(report.totalNettPrice)} zł
                </MainTableItem>
              </MainTableItemContainer>
              <MainTableItemContainer>
                <MainTableItem span={19} style={{ textAlign: "left", color: "#7B695F" }}>
                  SUMA brutto ({report.vat}% VAT)
                </MainTableItem>
                <MainTableItem
                  span={5}
                  style={{
                    fontWeight: "bold",
                    color: "#006BCC",
                    fontSize: "18px",
                    textAlign: "right",
                    paddingRight: "16px",
                  }}
                >
                  {showMoney(report.totalPrice)} zł
                </MainTableItem>
              </MainTableItemContainer>
            </MainTableContainer>
          </PageContentContent>
        </PageContent>

        <PageFooter />
      </PageWrapper>
    </PageContainer>
  );
};
export default Page3;

const PageContainer = styled.div`
  display: table;
  margin: 0 auto;
  width: 794px !important;
  height: 1123px !important;
  background: #ffffff;
`;

const PageWrapper = styled.div`
  padding: 28px 36px 28px 36px;
  height: calc(1123px - 56px) !important;
`;

const PageContent = styled.div`
  height: 847px !important;
`;

const PageContentContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MainTableContainer = styled.div`
  margin-top: 32px;
  padding: 0px 32px 32px 32px;
  background-color: #f5f5f5;
`;

const MainTableItemContainer = styled(Row)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #0555a0;
`;

const MainTableItem = styled(Col)`
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  padding: 11px 8px;
`;
