import React, { FC } from "react";
import styled from "styled-components";
import logo from "../assets/images/bat_centrum_dachowe_logo.png";
import { getBasicUrlLogoImage } from "../Helper";

type Props = {
  imageUrl: string;
};

const Logo: FC<Props> = ({ imageUrl }) => {
  return (
    <Container>
      <LogoImage src={`${getBasicUrlLogoImage()}${imageUrl}`} />
    </Container>
  );
};
export default Logo;

const Container = styled.div`
  display: block;
  width: 100%;
`;

const LogoImage = styled.img`
  width: 100%;
  height: auto;
`;
