import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Line, Group, Text } from "react-konva";
import { Html } from "react-konva-utils";

const getXText = (points, rotated) => {
  if (rotated) {
    return (points[2] + points[0]) / 2 - 15;
  } else {
    return (points[2] + points[0]) / 2 - 5;
  }
};

const getYText = (points, rotated) => {
  if (rotated) {
    return (points[3] + points[1]) / 2 + 10;
  } else {
    return (points[3] + points[1]) / 2 - 15;
  }
};

const getPointsBottomHelper = (points) => {
  const result = [];

  result.push(points[0] - 5);
  result.push(points[1]);
  result.push(points[0] + 5);
  result.push(points[1]);

  return result;
};

const getPointsTopHelper = (points) => {
  const result = [];

  result.push(points[2] - 5);
  result.push(points[3]);
  result.push(points[2] + 5);
  result.push(points[3]);

  return result;
};

const getPoitsLeftHelper = (points) => {
  const result = [];

  result.push(points[0]);
  result.push(points[1] - 5);
  result.push(points[0]);
  result.push(points[1] + 5);

  return result;
};

const getPoitsRightHelper = (points) => {
  const result = [];

  result.push(points[2]);
  result.push(points[3] - 5);
  result.push(points[2]);
  result.push(points[3] + 5);

  return result;
};

export default function DimLine(props) {
  return (
    <Container>
      <Line points={props.points} closed={false} stroke={"#000"} strokeWidth={1} />
      {props.rotated === false && (
        <Group>
          <Line points={getPoitsLeftHelper(props.points)} closed={false} stroke={"#000"} strokeWidth={1} />
          <Line points={getPoitsRightHelper(props.points)} closed={false} stroke={"#000"} strokeWidth={1} />
        </Group>
      )}

      {props.rotated === true && (
        <Group>
          <Line points={getPointsBottomHelper(props.points)} closed={false} stroke={"#000"} strokeWidth={1} />
          <Line points={getPointsTopHelper(props.points)} closed={false} stroke={"#000"} strokeWidth={1} />
        </Group>
      )}

      <Text
        rotation={props.rotated ? -90 : 0}
        x={getXText(props.points, props.rotated)}
        y={getYText(props.points, props.rotated)}
        text={`${props.distance.toFixed(2)} m`}
      />
    </Container>
  );
}

const Container = styled(Group)``;
