import React, { FC, useLayoutEffect, useRef, useState } from "react";
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter";
import styled from "styled-components";
import Konva from "konva";
import { Layer, Stage } from "react-konva";
import RoofPart from "../components/RoofPart";
import { getCorrectedPoints } from "./Page1";
import HoleComponent from "../components/HoleComponent";
import Edge from "../components/Edge";
import DimLine from "../components/DimLine";
import { Col, Row } from "antd";
import { IAdditionalPage, IReportReturnModel } from "../Model";
import { getBasicUrlAdditionalPageImage } from "../Helper";

const MARGIN = 90;
const HEIGHT_DRAW_AREA = 620 - 50; // 30 to wys tekstu Podsumowanie geometryczne dachu
const WIDTH_DRAW_AREA = 690;

type Props = { page: IAdditionalPage };

const AdditionalPage: FC<Props> = ({ page }) => {
  return (
    <PageContainer>
      <PageWrapper>
        <PageContent>
          <PageContentContent>
            <ImageSection>
              <img
                src={`${getBasicUrlAdditionalPageImage()}${page.imageUrl}`}
                style={{ maxWidth: "721px", maxHeight: "975px", objectFit: "contain" }}
                alt="DOMA"
              />
            </ImageSection>
          </PageContentContent>
        </PageContent>

        <PageFooter />
      </PageWrapper>
    </PageContainer>
  );
};
export default AdditionalPage;

const PageContainer = styled.div`
  display: table;
  margin: 0 auto;
  width: 794px !important;
  height: 1123px !important;
  background: #ffffff;
`;

const PageWrapper = styled.div`
  padding: 28px 36px 28px 36px;
  height: calc(1123px - 56px) !important;
`;

const PageContent = styled.div`
  height: 1027px !important;
`;

const PageContentContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ImageSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95%;
  margin-top: 2.5%;
`;
