export const formatNumber = (value: any): string => {
  const number = Number(value); // Próba konwersji wartości na liczbę
  if (isNaN(number)) {
    // Jeśli wartość nie jest liczbą, zwróć ją bez zmian lub zwróć pusty string, błąd itp.
    console.error("formatNumber: provided value cannot be converted to a number", value);
    return ""; // lub wartość domyślna, która ma sens w kontekście użycia
  }

  // Jeśli wartość jest liczbą, kontynuuj z logiką formatowania
  if (number % 1 !== 0) {
    return number.toFixed(2);
  } else {
    return number.toString();
  }
};

// Pobierz id kategorii po kodzie
export const getCategoryIdByCode = (codes: any, categories: any[]) => {
  const codesArray = Array.isArray(codes) ? codes : [codes];

  return codesArray.map((code) => {
    const category = categories.find((x) => x.code === code);
    return category ? category.id : null;
  });
};

// Pobierz wszystkie produkty dla wskazanych kategorii
export const getProductsByCategoryIds = (categoryIds: any[], products: any[]) => {
  const idsArray = Array.isArray(categoryIds) ? categoryIds : [categoryIds];

  return products.filter((product: any) => idsArray.includes(product.product.productCategoryId));
};

export const getDataProducts = (products: any[], vat: number) => {
  const data = [];
  for (const p of products) {
    const nettValue = Number(p.sumPrice.toFixed(2));
    const grossvalue = nettValue * (1 + vat / 100);
    data.push({
      id: p.id,
      productName: p.name,
      amount: p.amount.toFixed(2),
      unit: p.unit,
      priceUnit: p.unitPrice.toFixed(2),
      priceSum: nettValue.toFixed(2),
      priceGross: grossvalue.toFixed(2),
    });
  }

  return data;
};

export const getBasicUrlLogoImage = () => {
  return `https://s3.doma.pl/loga/`;
};

export const getBasicUrlSystemImage = () => {
  return `https://s3.doma.pl/systemy/`;
};

export const getBasicUrlAdditionalPageImage = () => {
  return `https://s3.doma.pl/raport/`;
};

export const showMoney = (value: number) => {
  return (
    value.toLocaleString("fr-FR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) || 0
  );
};

export const getEdgeNameByType = (type: any) => {
  switch (type) {
    case 1:
      return `Okap`;
    case 2:
      return `Inne`;
    case 3:
      return `Kalenica narożna`;
    case 4:
      return `Koszowa`;
    case 5:
      return `Kalenica`;
    case 6:
      return `Szczytowa lewa`;
    case 7:
      return `Szczytowa prawa`;
    case 8:
      return `2 pionowe połacie`;
    case 9:
      return `Otwór`;
    case 10:
      return `Dach-ściana`;
    case 11:
      return `Okno`;
    case 12:
      return `Komin`;
    default:
      return "Inna";
  }
};

export const getColorByEdgeType = (type: number) => {
  switch (type) {
    case 1:
      return "#98b2f8";
    case 2:
      return `#d1a2a5`;
    case 3:
      return `#a2b30f`;
    case 4:
      return `#7a7a7a`;
    case 5:
      return `#00FF00`;
    case 6:
      return `#82a582`;
    case 7:
      return `#5a7e5a`;
    case 8:
      return `#d1a2a5`;
    case 9:
      return `#b49193`;
    case 10:
      return `#cc9658`;
    case 11:
      return `#cfc5b9`;
    case 12:
      return `#c0b09d`;
    default:
      return "#b69494";
  }
};
