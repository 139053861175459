import { Tag, Tooltip, Button, Row, Col, List } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Countdown, { CountdownRendererFn, CountdownTimeDelta } from "react-countdown";
import styled from "styled-components";
import AutoPages from "../pages/AutoPages";
import Page1 from "../pages/Page1";
import Page2 from "../pages/Page2";
import Page3 from "../pages/Page3";
import Space from "./Space";
import GeometricPage from "../pages/GeometricPage";
import NotesPage from "../pages/NotesPage";
import AdditionalPage from "../pages/AdditionalPage";
import { IInvestmentReturnModel, IReportReturnModel } from "../Model";
import { showMoney } from "../Helper";

// Definiowanie typów dla argumentów funkcji renderer
interface RendererProps extends CountdownTimeDelta {
  completed: boolean;
}

// Implementacja funkcji renderer z typami
const renderer: CountdownRendererFn = ({ days, hours, minutes, seconds, completed }: RendererProps) => {
  if (completed) {
    return <div>Oferta straciła ważność. Skontaktuj się z doradcą.</div>;
  } else {
    return (
      <span>
        {days} dni {hours} godzin {minutes} minut {seconds} sekundy
      </span>
    );
  }
};

export default function Report(props: any) {
  const navigate = useNavigate();
  let { pathname } = useLocation();

  const [investment, setInvestment] = useState<IInvestmentReturnModel | null>(null);
  const [activeReportCode, setActiveReportCode] = useState(undefined);

  const [clickCount, setClickCount] = useState(0);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (props.investment) {
      setInvestment(props.investment);
    }

    if (props.activeReportCode) {
      setActiveReportCode(props.activeReportCode);
    }
  }, [props]);

  const isShortReport = () => {
    return pathname.split("/")[3] === "short";
  };

  // Definicja typów dla funkcji onSuccess i onFailure
  type CallbackFunction = () => void;

  const handleSelectReportClick = (threeClick: CallbackFunction, otherClick: CallbackFunction) => {
    const now = Date.now();
    const timeDifference = now - lastClickTime;

    if (timeDifference < 500) {
      setClickCount((prevCount) => prevCount + 1);

      if (clickCount + 1 === 3) {
        // console.log("3 szybkie kliknięcia!");
        setClickCount(0);
        if (timeoutId) clearTimeout(timeoutId);
        threeClick();
      } else {
        // Resetuj timeout przy każdym kliknięciu, które nie kończy sekwencji
        if (timeoutId) clearTimeout(timeoutId);
        const newTimeoutId = setTimeout(() => {
          // console.log("Sekwencja kliknięć niekompletna.");
          setClickCount(0);
          otherClick();
        }, 500); // Czekaj 500ms na kolejne kliknięcie
        setTimeoutId(newTimeoutId);
      }
    } else {
      // Zresetuj licznik, jeśli kliknięcia są zbyt wolne
      setClickCount(1);
      if (timeoutId) clearTimeout(timeoutId);
      const newTimeoutId = setTimeout(() => {
        // console.log("Sekwencja kliknięć niekompletna.");
        setClickCount(0);
        otherClick();
      }, 500);
      setTimeoutId(newTimeoutId);
    }

    setLastClickTime(now);
  };

  // Funkcja do sprawdzenia, czy urządzenie to urządzenie mobilne
  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  interface RowButtonProps {
    title: string;
    name: string;
    noContent: boolean;
  }

  const RowButton: React.FC<RowButtonProps> = ({ title, name, noContent }) => {
    return (
      <Row style={{ opacity: noContent ? 0.3 : 1 }}>
        <Col
          span={10}
          style={{ display: "flex", justifyContent: "flex-start", fontSize: "12px", fontWeight: "500" }}
        >
          {title}
        </Col>
        <Col
          span={14}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row-reverse",
            textAlign: "right",
            fontSize: "12px",
            minWidth: "0",
          }}
        >
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              minWidth: "0",
            }}
            title={name}
          >
            {name}
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Container>
      {investment && (
        <>
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <CounterContainer>
              <div style={{ fontWeight: "bold", fontSize: "18px", color: "#696969" }}>
                Oferta jest ważna jeszcze przez:
              </div>
              <Tag
                color="#ee1010"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "16px 16px",
                  marginTop: "16px",
                }}
              >
                <div style={{ fontWeight: "bold", color: "#ffffff", fontSize: "22px" }}>
                  <Countdown date={new Date(investment.reports[0].expiryDate)} renderer={renderer} />
                </div>
              </Tag>
            </CounterContainer>
          </div>

          <Space />

          <SelectReportContainer>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#696969",
                textAlign: "center",
              }}
            >
              W tej inwestycji dostępne są następujące kalkulacje:
            </div>

            <SystemList
              grid={{
                xxl: investment.reports.length === 1 ? 1 : investment.reports.length === 2 ? 2 : 3,
                xl: investment.reports.length === 1 ? 1 : investment.reports.length === 2 ? 2 : 3,
                lg: 1,
                md: 1,
                sm: 1,
                xs: 1,
              }}
              style={{ display: "flex", flexDirection: "column", margin: "0px", width: "100%" }} // Dodaj tę linię
              dataSource={investment.reports}
              renderItem={(item: any, index: number) => {
                return (
                  <List.Item style={{ margin: "8px", padding: "0px" }}>
                    <ReportButton
                      type={item.reportNumber === activeReportCode ? "primary" : "default"}
                      key={index}
                      onClick={() => {
                        handleSelectReportClick(
                          () => {
                            const code = "short/" + Date.now() + Math.random().toString(36).substr(2, 20);
                            return navigate(`${props.investmentCode}/${item.reportNumber}/${code}`);
                          },
                          () => {
                            navigate(`${props.investmentCode}/${item.reportNumber}`);
                          }
                        );
                      }}
                    >
                      <RowButton
                        title="System pokrycia"
                        name={item.roofSystemName.length > 0 ? item.roofSystemName : "Brak wybranego systemu"}
                        noContent={item.roofSystemName.length === 0}
                      />
                      <RowButton
                        title="System orynnowania"
                        name={item.gutterSystemName.length > 0 ? item.gutterSystemName : "Brak wybranego systemu"}
                        noContent={item.gutterSystemName.length === 0}
                      />
                      <RowButton
                        title="System krycia wstępnego"
                        name={
                          item.preCoveringSystemName.length > 0
                            ? item.preCoveringSystemName
                            : "Brak wybranego systemu"
                        }
                        noContent={item.preCoveringSystemName.length === 0}
                      />
                      <RowButton
                        title="System zamocowań"
                        name={
                          item.fasteningSystemName.length > 0 ? item.fasteningSystemName : "Brak wybranego systemu"
                        }
                        noContent={item.fasteningSystemName.length === 0}
                      />
                      <RowButton
                        title="Cena netto"
                        name={`${showMoney(item.totalNettPrice)} zł`}
                        noContent={item.totalNettPrice.length === 0}
                      />
                      <RowButton title="VAT" name={`${item.vat} %`} noContent={item.vat.length === 0} />
                      <RowButton
                        title="Cena brutto"
                        name={`${showMoney(item.totalPrice)} zł`}
                        noContent={item.totalPrice.length === 0}
                      />
                    </ReportButton>
                  </List.Item>
                );
              }}
            />
          </SelectReportContainer>

          <Space />

          {investment.reports.map((report: IReportReturnModel, index: number) => {
            if (report.reportNumber === activeReportCode) {
              if (isShortReport()) {
                return (
                  <div key={index}>
                    {/* Strona z geometrią - jeśłi pełny raport */}
                    {report.isFull && <GeometricPage report={report} />}
                    {report.isFull && <Space />}
                    {/* Produkty */}
                    <AutoPages report={report} short={true} />
                  </div>
                );
              } else {
                return (
                  <div key={index}>
                    {/* Strona 1 - stała */}
                    <Page1 report={report} />
                    <Space />
                    {/* Strona 2 - stała */}
                    <Page2 report={report} />
                    <Space />
                    {/* Strona 3 - stała */}
                    <Page3 report={report} />
                    <Space />
                    {/* Produkty */}
                    <AutoPages report={report} short={false} />
                    {/* Strona z geometrią - stała */}
                    {report.isFull && <GeometricPage report={report} />}
                    {report.isFull && <Space />}
                    {/* Strona z uwagami - stała */}
                    <NotesPage report={report} />
                    <Space />
                    {/* W pętli lista stron dodatkowych */}
                    {report.additionalPages.map((page, index) => {
                      return (
                        <div key={index}>
                          <AdditionalPage page={page} />
                          <Space />
                        </div>
                      );
                    })}
                  </div>
                );
              }
            }
          })}
        </>
      )}

      <Tooltip
        title={
          <span>
            Zadzwoń do doradcy technicznego! Telefon: <b>+48 {investment?.userPhone}</b>
          </span>
        }
        placement="topRight"
        color={"#2db7f5"}
      >
        <CallContainer>
          <a
            href={isMobileDevice() ? `tel:+48${investment?.userPhone}` : "#"}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            Doradca techniczny
          </a>
        </CallContainer>
      </Tooltip>
    </Container>
  );
}

const Container = styled.div`
  margin: auto;
`;

const CounterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* max-width: 900px;
  min-width: 0; */
  /* width: 794px; */
  margin-top: 32px;
  font-size: 24px;

  @media print {
    display: none;
  }
`;

const SelectReportContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 16px 0px;

  @media print {
    display: none;
  }
`;

const ReportButton = styled(Button)`
  /* white-space: normal; // Pozwala tekstowi na przejście do nowego wiersza */
  padding: 16px 16px; // Możesz dostosować padding, aby lepiej wyglądało
  font-weight: bold;
  font-size: 12px;
  width: 100%;
  height: 100%;

  @media print {
    display: none;
  }
`;

const CallContainer = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  padding: 16px;
  background: #fff;
  border-radius: 8px;

  cursor: pointer;

  @media print {
    display: none;
  }

  &:hover {
    background: #2db7f5;
    color: #fff;
  }
`;

const SystemList = styled(List)`
  margin-top: 16px;
`;
